import React, { useState } from "react";
import * as api from "../../../api/index.js";
import LoadingSvg from "../../../components/elements/loadings/LoadingSvg.js";
import { POSTS_MANAGEMENT_CONTENT } from "../../../utils/constants.js";
import { Button, Input } from "antd";

const CreateList = ({
  userData,
  setUserData,
  translations,
  setPostsManagementContent,
}) => {
  const [newListTitle, setNewListTitle] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const createList = async ({ title }) => {
    setIsLoading(true);
    const { create_list_success } = await api.lists.create({
      uid: userData.uid,
      title,
    });
    if (!create_list_success) {
      // TO DO handle errors
    }
    // update list after creating new list
    const {
      get_lists_error,
      data: user_lists,
      get_lists_success,
    } = await api.lists.get({ uid: userData.uid });
    if (get_lists_success) {
      setUserData((prev) => ({ ...prev, lists: user_lists }));
    } else {
      console.log(get_lists_error);
    }
    setPostsManagementContent(POSTS_MANAGEMENT_CONTENT.LISTS);
    setIsLoading(false);
  };

  return (
    <div className="grid grid-cols-10">
      {isLoading && (
        <div className="col-span-10 h-[100px] flex items-center justify-center">
          <LoadingSvg />
        </div>
      )}
      {!isLoading && (
        <div className="col-span-10 flex flex-col pt-2 gap-4">
          <Input
            value={newListTitle}
            className="p-2"
            onChange={(e) => setNewListTitle(e.target.value)}
            placeholder={translations.my_account_create_list_title}
          />
          <Button
            className="w-full py-4 font-medium"
            type="primary"
            onClick={() => {
              createList({ title: newListTitle });
            }}
          >
            {translations.account_lists_create_list}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateList;
