import {
  signOut,
  updatePassword,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { PROVIDERS } from "../../utils/constants";

const login = async ({ email, provider, firebase, password }) => {
  let user;
  try {
    if (provider !== PROVIDERS.EMAIL) {
      ({ user } = await signInWithPopup(firebase.auth, firebase[provider]));
    } else {
      ({ user } = await signInWithEmailAndPassword(
        firebase.auth,
        email,
        password
      ));
    }
    return {
      user,
      uid: user.uid,
      login_success: true,
      email_verified: user.emailVerified,
    };
  } catch (err) {
    return { login_success: false, login_error: err };
  }
};

const refreshToken = async ({ firebase }) => {
  try {
    const x = await firebase.auth.currentUser.getIdToken(true);
    console.log(x);
    return {
      refresh_user_token_success: true,
    };
  } catch (err) {
    console.log(err);
    return {
      refresh_user_token_success: false,
      refresh_user_token_error: err,
    };
  }
};

const logout = async ({ firebase }) => {
  try {
    await signOut(firebase.auth);
    return { logout_success: true };
  } catch (err) {
    console.log(err);
    return { logout_success: false, logout_error: err };
  }
};

const updateUserPassword = async ({ firebase, newPassword }) => {
  try {
    await updatePassword(firebase.auth.currentUser, newPassword);
    return { update_password_success: true };
  } catch (err) {
    console.log(err);
    return { update_password_success: false, update_password_error: err };
  }
};

export { login, logout, refreshToken, updateUserPassword as updatePassword };
