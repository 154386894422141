const setup = () => {
  const api_user = process.env.REACT_APP_BACKEND_USERNAME;
  const api_password = process.env.REACT_APP_BACKEND_PASSWORD;
  const auth = "Basic " + btoa(api_user + ":" + api_password);
  const headers = {
    Authorization: auth,
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  };
  return {
    options: { withCredentials: true, headers },
    api_endpoint: process.env.REACT_APP_BACKEND_ENDPOINT,
  };
};

export default setup;
