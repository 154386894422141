import axios from "axios";
import setup from "../setup";
const { options, api_endpoint } = setup();

const getNewsletters = async ({ language }) => {
  let get_newsletters_success = false;
  const url = `${api_endpoint}users/newsletters/getall?language_id=${language.id}`;

  try {
    const { status, data } = await axios.get(url, options);
    get_newsletters_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return {
          get_newsletters_success,
          data: data.data.map((n) => ({
            ...n,
            active: false,
            value: n.id_newsletter,
          })),
        };
      default:
        // TO DO handle all others cases as per the docs
        return { get_newsletters_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      get_newsletters_success,
      get_newsletters_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const unsubscribeNewsletter = async (post_data) => {
  let unsubscribe_newsletter_success = false;
  const url = `${api_endpoint}users/newsletters/unsubscribe`;

  try {
    const { status } = await axios.post(url, post_data, options);
    unsubscribe_newsletter_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { unsubscribe_newsletter_success };
      default:
        // TO DO handle all others cases as per the docs
        return { unsubscribe_newsletter_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      unsubscribe_newsletter_success,
      unsubscribe_newsletter_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const subscribeNewsletter = async (post_data) => {
  let subscribe_newsletter_success = false;
  const url = `${api_endpoint}users/newsletters/subscribe`;

  try {
    const { status } = await axios.post(url, post_data, options);
    subscribe_newsletter_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { subscribe_newsletter_success };
      default:
        // TO DO handle all others cases as per the docs
        return { subscribe_newsletter_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      subscribe_newsletter_success,
      subscribe_newslette_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const getUserNewsletters = async ({ uid }) => {
  let get_user_newsletters_success = false;
  const url = `${api_endpoint}users/newsletters/getdata/?uid=${uid}`;

  try {
    const { status, data } = await axios.get(url, options);
    get_user_newsletters_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { get_user_newsletters_success, data: data.data };
      default:
        // TO DO handle all others cases as per the docs
        return { get_user_newsletters_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      get_user_newsletters_success,
      get_user_newsletters_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

export {
  getNewsletters as get,
  getUserNewsletters as subscribed,
  subscribeNewsletter as subscribe,
  unsubscribeNewsletter as unsubscribe,
};
