import axios from "axios";
import setup from "../setup";

const { options, api_endpoint } = setup();

const createUserProviderAssociation = async ({ uid, provider }) => {
  try {
    const url = `${api_endpoint}users/providers/create`;
    const post_data = { uid, provider_id: provider };
    const response = await axios.post(url, post_data, options);

    switch (response.status) {
      case 200:
        // successfull user provider association creation
        return { create_user_provider_success: true };
      default:
        // TO DO handle all others cases as per the docs
        return {
          create_user_provider_success: false,
          create_user_provider_error: "TO DO",
        };
    }
  } catch (err) {
    console.log(err);
    return {
      create_user_provider_success: false,
      create_user_provider_error: err,
    };
  }
};

const deleteUserProviderAssociation = async ({ uid, provider }) => {
  try {
    const url = `${api_endpoint}users/providers/delete`;
    const post_data = { uid, provider_id: provider };
    const response = await axios.post(url, post_data, options);

    switch (response.status) {
      case 200:
        // successfull user provider association deletion
        return { delete_user_provider_success: true };
      default:
        // TO DO handle all others cases as per the docs
        return {
          delete_user_provider_success: false,
          delete_user_provider_error: "TO DO",
        };
    }
  } catch (err) {
    console.log(err);
    return {
      delete_user_provider_success: false,
      delete_user_provider_error: err,
    };
  }
};

export {
  createUserProviderAssociation as create,
  deleteUserProviderAssociation as delete,
};
