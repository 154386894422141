import React from "react";

const Notification = ({ notification: { message, open } }) => {
  return (
    <div className={`notification ${open ? "show-notification" : ""}`}>
      <div className="notification-container">{message}</div>
    </div>
  );
};

export default Notification;
