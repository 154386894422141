// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = require("./firebase.config.json");

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firebase = {
  auth: getAuth(app),
  google: new GoogleAuthProvider(),
};

export { firebase };
