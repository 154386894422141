import axios from "axios";
import setup from "../setup";
const { options, api_endpoint } = setup();

const getLists = async ({ uid }) => {
  let get_lists_success = false;
  const url = `${api_endpoint}users/lists/getdata/?uid=${uid}`;

  try {
    const { status, data } = await axios.get(url, options);
    get_lists_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { get_lists_success, data: data.data };
      default:
        // TO DO handle all others cases as per the docs
        return { get_lists_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      get_lists_success,
      get_lists_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const deleteList = async (post_data) => {
  let delete_list_success = false;
  const url = `${api_endpoint}users/lists/delete`;

  try {
    const response = await axios.post(url, post_data, options);
    delete_list_success = true;
    switch (response.status) {
      case 200:
        // successfull user data retrieval
        return { delete_list_success };
      default:
        // TO DO handle all others cases as per the docs
        return { delete_list_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      delete_list_success,
      delete_list_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const createList = async (post_data) => {
  let create_list_success = false;
  const url = `${api_endpoint}users/lists/create`;

  try {
    const { status, data } = await axios.post(url, post_data, options);
    console.log(data);
    create_list_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { create_list_success, ...data };
      default:
        // TO DO handle all others cases as per the docs
        return { create_list_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      create_list_success,
      create_list_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

export { getLists as get, createList as create, deleteList as delete };
