import axios from "axios";
import setup from "../setup";
const { options, api_endpoint } = setup();

const unsubscribeNotification = async (post_data) => {
  let unsubscribe_notification_success = false;
  const url = `${api_endpoint}users/notifications/delete`;

  try {
    const { status } = await axios.post(url, post_data, options);
    unsubscribe_notification_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { unsubscribe_notification_success };
      default:
        // TO DO handle all others cases as per the docs
        return { unsubscribe_notification_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      unsubscribe_notification_success,
      unsubscribe_notification_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const subscribeNotification = async (post_data) => {
  let subscribe_notification_success = false;
  const url = `${api_endpoint}users/notifications/create`;

  try {
    const { status } = await axios.post(url, post_data, options);
    subscribe_notification_success = true;
    switch (status) {
      case 200:
        // successfull notification creation
        return { subscribe_notification_success };
      default:
        // TO DO handle all others cases as per the docs
        return { subscribe_notification_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      subscribe_notification_success,
      subscribe_newslette_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const getUserNotifications = async ({ uid }) => {
  let get_notifications_success = false;
  const url = `${api_endpoint}users/notifications/getdata/?uid=${uid}`;

  console.log(url);
  try {
    const { status, data } = await axios.get(url, options);
    get_notifications_success = true;
    switch (status) {
      case 200:
        // successfull user data retrieval
        return { get_notifications_success, data: data.data };
      default:
        // TO DO handle all others cases as per the docs
        return { get_notifications_success };
    }
  } catch (err) {
    const { response } = err;
    return {
      get_notifications_success,
      get_notifications_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

export {
  getUserNotifications as subscribed,
  subscribeNotification as subscribe,
  unsubscribeNotification as unsubscribe,
};
