import axios from "axios";
import setup from "../setup";
const { options, api_endpoint } = setup();

const getCountries = async ({ language }) => {
  let get_countries_success = false;
  const url = `${api_endpoint}users/getcountries/?language_id=${language.id}`;

  try {
    const { status, data } = await axios.get(url, options);
    switch (status) {
      case 200:
        // successfull user data retrieval
        get_countries_success = true;
        data.data.sort();
        return {
          get_countries_success,
          data: data.data.map((c) => ({
            value: c.id_country,
            label: c.country_name,
          })),
        };
      default:
        // TO DO handle all others cases as per the docs
        return { get_countries_success, get_countries_error: "TO DO" };
    }
  } catch (err) {
    console.log(err);
    return { get_countries_success, get_countries_error: err };
  }
};

export { getCountries as get };
