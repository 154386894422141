import axios from "axios";
import setup from "../setup";
import * as auth from "firebase/auth";
import { PROVIDERS } from "../../utils/constants";

const { options, api_endpoint } = setup();

const deleteUser = async ({ firebase, firebase_only = false }) => {
  let delete_user_success = false;
  const { uid } = firebase.auth.currentUser;
  try {
    await auth.deleteUser(firebase.auth.currentUser);

    if (!firebase_only) {
      const url = `${api_endpoint}users/delete`;
      const post_data = { uid };
      const response = await axios.post(url, post_data, options);

      switch (response.status) {
        case 200:
          // successfull user creation
          delete_user_success = true;
          return { delete_user_success };
        default:
          // TO DO handle all others cases as per the docs
          return { delete_user_success, delete_user_error: "TO DO" };
      }
    }
    delete_user_success = true;
    return { delete_user_success };
  } catch (err) {
    console.log(err);
    return { delete_user_success: false, delete_user_error: err };
  }
};

const getProviderData = (
  user,
  provider,
  { user_email, user_lastname, user_firstname, user_accepted_terms }
) => {
  switch (provider) {
    case PROVIDERS.GOOGLE:
      const names = user.displayName.split(" ");
      const first_name = names.shift();
      return {
        uid: user.uid,
        id_country: 183, // PT
        email_verified: 1,
        user_email: user.email,
        user_accepted_terms: 1,
        user_firstname: first_name,
        user_lastname: names.join(" "),
      };
    case PROVIDERS.EMAIL:
      return {
        user_email,
        uid: user.uid,
        user_lastname,
        user_firstname,
        id_country: 183, // PT
        email_verified: 0,
        user_accepted_terms: 1,
      };
    default:
      console.log(`Provider ${provider} is not yet supported.`);
  }
};

const createUser = async ({ firebase, provider, ...user_data }) => {
  let create_error,
    create_success = false,
    user;
  const email_verified = provider !== PROVIDERS.EMAIL;
  const url = `${api_endpoint}users/create`;

  // sign in using firebase providers
  try {
    if (provider !== PROVIDERS.EMAIL) {
      ({ user } = await auth.signInWithPopup(
        firebase.auth,
        firebase[provider]
      ));
    } else {
      ({ user } = await auth.createUserWithEmailAndPassword(
        firebase.auth,
        user_data.user_email,
        user_data.user_password
      ));
    }
  } catch (err) {
    create_error = { code: err.code };
  }

  // if firebase login returned an user
  if (user) {
    // send verification email if email and password register
    if (provider === PROVIDERS.EMAIL) {
      try {
        await auth.sendEmailVerification(firebase.auth.currentUser);
      } catch (err) {
        create_error = { code: err.code };
      }
    }
    if (!create_error?.code) {
      const { uid } = user;
      const post_data = getProviderData(user, provider, user_data);
      // create user on the backend
      try {
        const response = await axios.post(url, post_data, options);
        switch (response.status) {
          case 200:
            // successfull user creation
            create_success = true;
            return { create_success, uid, email_verified };
          default:
            console.log(response);
            return { create_success, email_verified };
        }
      } catch ({ response }) {
        switch (response.status) {
          case 400:
            return {
              create_success,
              create_error: { code: response.status, ...response.data },
            };
          case 500:
            return {
              create_success,
              create_error: { code: response.status, ...response.data },
            };
          default:
            // TO DO handle all others cases as per the docs
            return { create_success, create_error: "TO DO" };
        }
      }
    }
  }
  return { create_success, create_error };
};

const getUserData = async ({ uid }) => {
  let get_data_success = false;
  const url = `${api_endpoint}users/getdata/?uid=${uid}`;

  try {
    const { status, data } = await axios.get(url, options);
    switch (status) {
      case 200:
        // successfull user data retrieval
        get_data_success = true;
        return { get_data_success, data: data.data };
      default:
        // TO DO handle all others cases as per the docs
        return { get_data_success, get_data_error: "TO DO" };
    }
  } catch ({ response }) {
    switch (response.status) {
      case 404:
        return {
          get_data_success,
          get_data_error: { code: response.status, ...response.data },
        };
      default:
        // TO DO handle all others cases as per the docs
        return { get_data_success, get_data_error: "TO DO" };
    }
  }
};

const updateUserData = async ({ userData }) => {
  let update_data_success = false;
  const url = `${api_endpoint}users/update/`;
  const {
    uid,
    id_country,
    user_email,
    user_lastname,
    user_firstname,
    email_verified,
    user_accepted_terms,
  } = userData;

  const post_data = {
    uid,
    user_email,
    id_country,
    user_lastname,
    user_firstname,
    email_verified,
    user_accepted_terms,
  };

  const { status, data } = await axios.post(url, post_data, options);
  switch (status) {
    case 200:
      // successfull user data retrieval
      update_data_success = true;
      return { update_data_success, data: data.data };
    default:
      // TO DO handle all others cases as per the docs
      return { update_data_success, update_data_error: "TO DO" };
  }
};

export {
  getUserData as get,
  deleteUser as delete,
  createUser as create,
  updateUserData as update,
};
