import React, { useState } from "react";
import Lists from "./Lists.js";
import { IoArrowBackOutline } from "react-icons/io5";
import { POSTS_MANAGEMENT_CONTENT } from "../../../utils/constants.js";
import { Modal } from "antd";
import CreateList from "./CreateList.js";

const PostsManagementModal = ({
  userData,
  setUserData,
  translations,
  postsManagementContent,
  postsManagementModalState,
  setPostsManagementContent,
  setPostsManagementModalState,
}) => {
  return (
    <Modal
      title={
        postsManagementContent === POSTS_MANAGEMENT_CONTENT.LISTS ? (
          <div>{translations.post_added_add_to_a_list}</div>
        ) : (
          <div className="flex items-center">
            <span
              className="mr-4"
              onClick={() =>
                setPostsManagementContent(POSTS_MANAGEMENT_CONTENT.LISTS)
              }
            >
              <IoArrowBackOutline size={20} />
            </span>
            <div>{translations.post_added_create_a_new_list}</div>
          </div>
        )
      }
      centered
      closable
      width={400}
      open={postsManagementModalState.open}
      onCancel={() => {
        setPostsManagementModalState({ open: false });
      }}
      footer={null}
    >
      <div className="form main flex flex-col gap-4">
        {postsManagementContent === POSTS_MANAGEMENT_CONTENT.LISTS && (
          <Lists
            userData={userData}
            setUserData={setUserData}
            translations={translations}
            postsManagementModalState={postsManagementModalState}
            setPostsManagementContent={setPostsManagementContent}
          />
        )}
        {postsManagementContent === POSTS_MANAGEMENT_CONTENT.ADD_LIST && (
          <CreateList
            userData={userData}
            setUserData={setUserData}
            translations={translations}
            setPostsManagementContent={setPostsManagementContent}
          />
        )}
      </div>
    </Modal>
  );
};

export default PostsManagementModal;
