import React, { useState } from "react";
import * as api from "../../../api";
import LoadingSvg from "../../../components/elements/loadings/LoadingSvg.js";
import { IoBookmark, IoBookmarks, IoCheckmarkOutline } from "react-icons/io5";
import { POSTS_MANAGEMENT_CONTENT } from "../../../utils/constants.js";

const Lists = ({
  userData,
  setUserData,
  translations,
  postsManagementModalState,
  setPostsManagementContent,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const updatePostList = async (new_list_id) => {
    console.log(userData);
    setIsLoading(true);
    const post_data = {
      uid: userData.uid,
      list_id: new_list_id,
      item_id: userData.lists
        .find(
          (list) =>
            list.items.find(
              (i) => i.href === postsManagementModalState.post_data.href
            ) !== undefined
        )
        .items.find((i) => i.href === postsManagementModalState.post_data.href)
        .item_id,
    };
    const { update_post_error, update_post_success } = await api.posts.update(
      post_data
    );
    if (update_post_error) {
      console.log(update_post_error);
      // TO DO handle error
    }
    if (update_post_success) {
      // update lists after updating post list
      const {
        get_lists_error,
        data: user_lists,
        get_lists_success,
      } = await api.lists.get({ uid: userData.uid });
      if (get_lists_success) {
        setUserData((prev) => ({ ...prev, lists: user_lists }));
      } else {
        console.log(get_lists_error);
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="grid grid-cols-10">
      <div className="col-span-10 text-[#318CFC] border-b-[1px] py-4 cursor-pointer">
        <span
          onClick={() =>
            setPostsManagementContent(POSTS_MANAGEMENT_CONTENT.ADD_LIST)
          }
        >
          {translations.post_added_create_list}
        </span>
      </div>
      {isLoading && (
        <div className="col-span-10 h-[100px] flex items-center justify-center">
          <LoadingSvg />
        </div>
      )}
      {!isLoading && (
        <div className="col-span-10 flex flex-col divide-y last:border-b-[1px]">
          {userData.lists.map((list) => (
            <div
              key={list.list_id}
              onClick={() => updatePostList(list.list_id)}
              className="flex items-center cursor-pointer py-2 hover:bg-[#E5F1FF]"
            >
              <div>
                {list.list_id === userData.default_list.list_id ? (
                  <IoBookmarks size={25} />
                ) : (
                  <IoBookmark size={25} />
                )}
              </div>
              <div className="flex grow ml-4">{list.title}</div>
              <div className="mr-4">
                {list.items.find(
                  (i) => i.href === postsManagementModalState.post_data.href
                ) !== undefined ? (
                  <IoCheckmarkOutline size={25} />
                ) : null}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Lists;
