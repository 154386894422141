import axios from "axios";
import setup from "../setup";
const { options, api_endpoint } = setup();

const addPost = async (post_data) => {
  const url = `${api_endpoint}users/lists/post/create`;
  try {
    const { status, data } = await axios.post(url, post_data, options);
    switch (status) {
      case 200:
        // successful post addition
        return { add_post_success: true, ...data };
      default:
        // TO DO handle all others cases as per the docs
        return { add_post_success: false, add_post_error: "TO DO" };
    }
  } catch (err) {
    const { response } = err;
    return {
      add_post_success: false,
      add_post_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const removePost = async (post_data) => {
  const url = `${api_endpoint}users/lists/post/remove`;
  try {
    const { status, data } = await axios.post(url, post_data, options);
    switch (status) {
      case 200:
        // successful post removal
        return { remove_post_success: true, ...data };
      default:
        // TO DO handle all others cases as per the docs
        return { remove_post_success: false, remove_post_error: "TO DO" };
    }
  } catch (err) {
    const { response } = err;
    return {
      remove_post_success: false,
      remove_post_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

const updatePost = async (post_data) => {
  const url = `${api_endpoint}users/lists/post/update`;
  try {
    const { status, data } = await axios.post(url, post_data, options);
    switch (status) {
      case 200:
        // successful post update
        return { update_post_success: true, ...data };
      default:
        // TO DO handle all others cases as per the docs
        return { update_post_success: false, update_post_error: "TO DO" };
    }
  } catch (err) {
    const { response } = err;
    return {
      update_post_success: false,
      update_post_error: {
        code: response.status,
        message: response.data.message,
      },
    };
  }
};

export { addPost as create, removePost as delete, updatePost as update };
